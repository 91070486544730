import React from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";

import Menu from "../wma-list/menu";

import "core-js/proposals/string-match-all";//polyfill for old Androids; TODO - move away from here
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Root = styled('div')(({ theme }) => ({

}));
Root.defaultProps = {
    "data-id": "Root"
  };
// ----------------------------------------------------------
const HomeContainer = styled('div')(({ theme }) => ({
    height: 'calc(100vh - 10px)',
    maxHeight: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    margin: '0 8px',
    marginTop: -48,
}));
HomeContainer.defaultProps = {
    "data-id": "HomeContainer"
  };
// ----------------------------------------------------------
const BackgroundImage = styled(BgImage)(({ theme }) => ({
    flexGrow: 2,
    backgroundSize: "cover",
    flexBasis:"40%",
    backgroundPosition:"center top!important"
}));
BackgroundImage.defaultProps = {
    "data-id": "Header"
  };
// ----------------------------------------------------------
const Title1 = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 15,
    transform: 'translateY(-104%)',
    background: 'transparent',
    WebkitBackgroundSize: 'contain',
    backgroundSize: 'contain',
    height: 145,
    width: 110,
    color: 'transparent',

}));
// ----------------------------------------------------------
const Title2 = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 38,
    left: 14,
    height: 60,
    color: '#fff',
    fontFamily: 'frequenz',
    fontSize: 68,
    lineHeight: 1,
}));
// ----------------------------------------------------------
const ChildrenUl = styled('ul')(({ theme }) => ({
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    display: 'block',
    position:"relative"
}));
// ----------------------------------------------------------
ChildrenUl.defaultProps = {
    "data-id": "ChildrenUl"
  };
// ----------------------------------------------------------
const ChildLi = styled('li')(({ theme }) => ({
    margin:"0",
    padding:"0",
    position: 'relative',
    borderBottom: '1px solid',
    borderColor: theme.palette.mode === "light" ? "#333" : "#DDD",
    minHeight: 84,
    display: 'flex',
    alignItems: 'center',
}));
ChildLi.defaultProps = {"data-id": "ChildLi"};

// ----------------------------------------------------------
const ChildLiIcon = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
}));
ChildLiIcon.defaultProps = {
    "data-id": "ChildLiIcon"
  };

const ChildLink = styled(Link)(({ theme }) => ({
    display: 'block',
    textDecoration: 'none',
    padding: '15px 10px',
    paddingLeft: '4px',
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#333",
    backgroundPosition: '97% center',
    color: theme.palette.mode === "light" ? "#222" : "#FFF",
    fontSize: 18,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: '100%',
    height: '100%',
}));

const ChildDiv = styled('div')(({ theme }) => ({
}));

const EntityPage = (props) => {

    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;

    const image = getImage(entity.largeImage?.localFile);
    const { defaultLocale } = pageContext;

    return <Root className="wma-hp-list" >
        <Wrapper {...props} menu={Menu}  title={entity.title} transparentAppbar={true}>
            <HomeContainer  style={{ overflowX: 'hidden' }/*TODO - var*/}>
                <BackgroundImage image={image}  >
<div class="homelogo"><img src="/images/smh-logo.png" /></div>
                    <Title2>{entity.shortDesc}</Title2>
                </BackgroundImage>
                <ChildrenUl>
                <Title1>{entity.title}</Title1>
                    {children.map((child, n) => {
                        const url = getEntityUrl({ entity: child, defaultLocale });

                        return (
                            <ChildLi>

                                <ChildLink to={url}>{child.title}</ChildLink>
                                <ChildLiIcon>
                            <ArrowForwardIosIcon color="secondary" fontSize="small" />
                            </ChildLiIcon>
                            </ChildLi>
                        );
                    })}
                </ChildrenUl>


            </HomeContainer>
        </Wrapper>
    </Root>
}

export default EntityPage;